import React from 'react';

import "./style/Home.css";
import "../responsive/pages/style/HomeResponsive.js.css";

import ContactForm from "../components/ContactForm";
import pintxosImage from "../medias/pictures/illustrationPictures/PlateauxPintxos.jpg";
import tapasImage from "../medias/pictures/illustrationPictures/Tapas.jpg";
import buffetImage from "../medias/pictures/illustrationPictures/BuffetPintxos.jpg";
import {Link} from "react-router-dom";

function Home() {
    return (
        <>
            <>
                <section className="topBanner">
                    <div className="topBannerPicture">
                        <p className="topBannerQuote">"Un traiteur Espagnol, l'humain en plus."</p>
                    </div>
                </section>

                <section className="servicesBubbles">
                    <h2>Découvrez nos prestations à la carte</h2>

                    <div className="serviceBubble1  serviceBubble">
                        <div className="bubbleContent">
                            <img src={pintxosImage} alt="Plateaux de Pintxos" />
                            <div className="bubbleText">
                                <h3>Plateaux de Pintxos</h3>
                                <h4>La spécialité de notre établissement. Plus de 100 recettes gourmandes, salées ou sucrées, pour le plaisir de vos papilles.</h4>
                                <p>
                                    Traditionnellement servis dans les bars à pintxos au pays Basque Espagnol, <br/> le pintxo est une petite tartine garnie et surmontée d'un petit pic : "Pinchar" en espagnol signifie piquer !
                                </p>
                                <p>
                                    25€ le plateau de 10. <br/>
                                    3 pièces par personne en apéritif, 7 pièces pour un repas complet
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="serviceBubble2  serviceBubble">
                        <div className="bubbleContent">
                            <div className="bubbleText">
                                <h3>Bar à tapas</h3>
                                <h4>Une découverte culinaire entre la France et l'Espagne,<br/> à prix abordables</h4>
                                <p>
                                    Autre tradition Basque Espagnole, le tapa se décline sous toutes les formes : Croquetas, melon pesto et Serrano, tortilla, txistorra, pimientos del padròn, calamars... <br/>
                                    Autant de possibilités de satisfaire le palais de vos invités !
                                </p>
                                <p>
                                    À partir de 5€ la tapas.
                                </p>
                            </div>
                            <img src={tapasImage} alt="Bar à tapas" />
                        </div>
                    </div>

                    <div className="serviceBubble3 serviceBubble">
                        <div className="bubbleContent">
                            <img src={buffetImage} alt="Cocktail dinatoire" />
                            <div className="bubbleText">
                                <h3>Cocktail dinatoire</h3>
                                <h4>Le must pour une soirée élégante et décontractée.</h4>
                                <p>
                                    Un buffet livré directement sur place, pour un budget réglé au milimètre.
                                </p>
                                <p>
                                    Nos boîtes de transport sont faites pour être posées directement sur table <br/>
                                    Pas besoin de service, tout est déjà prêt.
                                </p>
                            </div>
                        </div>
                    </div>

                    <p>Envie d'en savoir plus ?</p>
                    <button className="discoverAllServicesButton"><Link to="/prestations">Découvrir toutes nos prestations</Link></button>
                </section>
            </>

            <div className="contact-form-section">
                <ContactForm />
            </div>
        </>
    );
}

export default Home;
