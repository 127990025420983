import React, { useState } from 'react';
import './style/Prestations.css';
import "../responsive/pages/style/PrestationsResponsive.css"

// Fonction pour importer dynamiquement toutes les images dans un objet
function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

// Charger toutes les images du dossier illustrationPictures
const images = importAll(require.context('../medias/pictures/illustrationPictures', false, /\.(png|jpe?g|svg)$/));

// Données des prestations directement intégrées dans le code
const prestationsData = [
    {
        id: 1,
        title: "Plateaux de Pintxos",
        main_image: "../medias/pictures/illustrationPictures/PlateauxPintxos.jpg",
        secondary_images: null,
        short_description: "Plus de 100 recettes variées pour un buffet gourmand.",
        long_description: "Traditionnellement servis dans les bars à pintxos au pays Basque Espagnol, le pintxo est une petite tartine garnie et surmontée d'un petit pic : \"Pinchar\" en espagnol signifie piquer ! <br/><br/>25€ le plateau de 10.<br/>3 pièces par personne en apéritif, 7 pièces pour un repas complet."
    },
    {
        id: 2,
        title: "Bar à Tapas",
        main_image: "../medias/pictures/illustrationPictures/Tapas.jpg",
        secondary_images: "../medias/pictures/illustrationPictures/CasuellaAbuela.jpg",
        short_description: "Une grande variété de tapas pour satisfaire tous les goûts.",
        long_description: "Notre bar à tapas vous offre une découverte culinaire entre l'Espagne et la France : Croquetas, melon pesto Serrano, tortilla, txistorra, pimientos del padron, calamars... Du végétarien à l'amateur de viande, il y en a pour tous les goûts. <br/>A partir de 5€ la tapa."
    },
    {
        id: 3,
        title: "Cocktail dînatoire",
        main_image: "../medias/pictures/illustrationPictures/PlateauxPintxos2.jpg",
        secondary_images: null,
        short_description: "Pour une soirée élégante et décontractée.",
        long_description: "Un buffet livré directement sur place, pour un budget réglé au millimètre.<br/><br/>Nos boîtes de transport sont faites pour être posées directement sur table.<br/>Pas besoin de service, tout est déjà prêt."
    },
    {
        id: 4,
        title: "Bar à vins / cocktails",
        main_image: "../medias/pictures/illustrationPictures/BarCocktails.jpg",
        secondary_images: "../medias/pictures/illustrationPictures/CocktailBleu.jpg; ../medias/pictures/illustrationPictures/CocktailMelon.jpg",
        short_description: "Professionnel à prix tous doux",
        long_description: "Toute notre équipe est formée régulièrement en mixologie, œnologie et zytologie, pour vous servir et répondre à toutes vos attentes avec le sourire, nous recrutons les meilleurs."
    },
    {
        id: 5,
        title: "Paella",
        main_image: "../medias/pictures/illustrationPictures/Paella.jpg",
        secondary_images: "../medias/pictures/illustrationPictures/PaellaDouble.jpg",
        short_description: "Un plat traditionnel Espagnol préparé avec soin.",
        long_description: "Idéal pour un brunch le dimanche midi, notre pœllon peut ravir jusqu'à 100 personnes. <br/>À partir de 15€/personne."
    },
    {
        id: 6,
        title: "Plateaux de charcuteries fines et fromages",
        main_image: "../medias/pictures/illustrationPictures/PlancheCharcuterieFromage.jpg",
        secondary_images: null,
        short_description: "Des produits de qualité pour un apéritif convivial.",
        long_description: "Nos planches ne comportent que des produits espagnols, du jambon Serrano au chorizo en passant par de la txistorra pour charcuterie au manchego pour le fromage, retrouvez le pays basque directement sur votre ardoise. <br/>À partir de 5€/personne."
    },
    {
        id: 7,
        title: "Découpe de jambon ibérique",
        main_image: "../medias/pictures/illustrationPictures/DecoupeJambon.jpg",
        secondary_images: null,
        short_description: "Pour une expérience gastronomique unique",
        long_description: "Un véritable jambon affiné, découpé devant vous par un cuisinier qualifié.<br/>Cebo de Campo : 475€  /  BELLOTA : 650€  /  Prestation Découpe : 50€/heure."
    },
    {
        id: 8,
        title: "Vin d'accueil",
        main_image: "../medias/pictures/illustrationPictures/ChrisMagnum.jpg",
        secondary_images: null,
        short_description: "Pour bien commencer la fête.",
        long_description: "Notre maître sommelier a sélectionné les meilleurs vins de France et du monde pour ravir tous les amateurs de vins.<br/>À partir de 7€ la bouteille."
    },
    {
        id: 9,
        title: "Animation culinaire",
        main_image: "../medias/pictures/illustrationPictures/ChorizoFlambe.jpg",
        secondary_images: "../medias/pictures/illustrationPictures/AnimationCulinairePistoletBulle.jpg",
        short_description: "Pour émerveiller vos convives et leur ouvrir l'appétit.",
        long_description: "Notre chef a acquis durant des années des techniques culinaires proches de la cuisine moléculaire pour étonner vos convives : Flambage, Bulles de vapeur, glace carbonique..."
    },
    {
        id: 10,
        title: "Animations d'ambiance",
        main_image: "../medias/pictures/illustrationPictures/AnimationBuzzers.jpg",
        secondary_images: "../medias/pictures/illustrationPictures/AnimationHypnose.jpg; ../medias/pictures/illustrationPictures/AnimationGospel.jpg",
        short_description: "Pour une ambiance conviviale et ludique tout au long de votre soirée",
        long_description: "Nous proposons plusieurs animations pour égayer votre soirée :<br/>- Organisation de Blind Tests : Un moment interactif pour divertir vos invités.- sur devis<br/>- Concert Gospel : Nos amis Swing Gospel Singers nous accompagnent au quotidien, et peuvent venir donner une représentation. - 600€<br/>- Soirée hypnose : Une animation de table en table subjuguante avec notre ami Nicolas. - 300€"
    }
];

function Prestations() {
    const [prestations] = useState(prestationsData);  // Utiliser les données locales
    const [selectedPrestation, setSelectedPrestation] = useState(null);  // Pour stocker la prestation sélectionnée
    const [isModalOpen, setIsModalOpen] = useState(false);  // Pour gérer l'ouverture de la modale

    // Gérer le clic sur une carte pour ouvrir la modale
    const handleClick = (prestation) => {
        setSelectedPrestation(prestation);
        setIsModalOpen(true);
    };

    // Gérer la fermeture de la modale
    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedPrestation(null);
    };

    return (
        <div className="prestations-container">
            <h2 className="prestationsTitle">Nos Prestations</h2>
            <div className="cards-grid">
                {prestations.map((prestation) => {
                    const imageName = prestation.main_image.split('/').pop();
                    const prestationImage = images[imageName];

                    return (
                        <div key={prestation.id} className="card" onClick={() => handleClick(prestation)}>
                            {prestationImage ? (
                                <img src={prestationImage} alt={prestation.title} className="card-image" />
                            ) : (
                                <p>Image non disponible</p>
                            )}
                            <h3 className="card-title">{prestation.title}</h3>
                            <p className="card-description">{prestation.short_description}</p>
                        </div>
                    );
                })}
            </div>

            {isModalOpen && selectedPrestation && (
                <div className="modal-overlay" onClick={handleClose}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>  {/* Empêche de fermer en cliquant dans la modale */}
                        <button className="modal-close-button" onClick={handleClose}>×</button>

                        {/* Images principales et secondaires */}
                        <div className={`modal-images-container ${selectedPrestation.secondary_images ? `has-secondary-${selectedPrestation.secondary_images.split(';').length}` : ''}`}>
                            {/* Image principale */}
                            <img src={images[selectedPrestation.main_image.split('/').pop()]} alt={selectedPrestation.title} className="modal-image" />

                            {/* Images secondaires si présentes */}
                            {selectedPrestation.secondary_images && (
                                selectedPrestation.secondary_images.split(';').map((secondaryImage, index) => {
                                    const imageName = secondaryImage.trim().split('/').pop();
                                    return (
                                        <img key={index} src={images[imageName]} alt={`Secondary ${index}`} className="modal-image" />
                                    );
                                })
                            )}
                        </div>

                        {/* Titre et description */}
                        <h3 className="modal-title">{selectedPrestation.title}</h3>

                        {/* Description détaillée avec balises <br/> */}
                        <p
                            className="modal-description"
                            dangerouslySetInnerHTML={{ __html: selectedPrestation.long_description }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Prestations;
