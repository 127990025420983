import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style/Header.css';
import '../responsive/components/style/HeaderResponsive.css'; // Importer les styles responsives
import logo from '../medias/chart/El pintxo logo seul.svg';
import MobileMenu from '../responsive/components/HeaderResponsive'; // Importer le menu burger mobile

function Header() {
    const [isSticky, setIsSticky] = useState(false);
    const [navHeight, setNavHeight] = useState(0); // Stocker la hauteur du menu

    useEffect(() => {
        const navbar = document.querySelector('nav');
        setNavHeight(navbar.offsetHeight); // Récupère la hauteur du menu

        const stickyOffset = navbar.offsetTop; // Position initiale du menu

        const handleScroll = () => {
            if (window.pageYOffset > stickyOffset) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header>
            <section className="topSection">
                <img src={logo} alt="Logo d'El Pintxo Events" className="siteLogo" />
                <h1 className="siteTitle">El Pintxo Event</h1>
            </section>
            {/* Placeholder pour combler l'espace lorsque sticky */}
            <div className="navPlaceholder" style={{ height: isSticky ? `${navHeight}px` : '0' }}></div>

            {/* Affichage du menu burger pour mobile uniquement */}
            <div className="mobileMenuContainer">
                <MobileMenu />
            </div>

            {/* Menu desktop */}
            <nav className={`desktopNav ${isSticky ? 'sticky' : ''}`}>
                {isSticky && (
                    <div className="stickyTopSection" style={{ height: `${navHeight}px` }}>
                        <img src={logo} alt="Logo d'El Pintxo Events" className="siteLogo" />
                        <h1 className="siteTitle">El Pintxo Events</h1>
                    </div>
                )}
                <ul>
                    <li><Link to="/">Accueil</Link></li>
                    <li><Link to="/prestations">Nos Prestations</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
