import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style/HeaderResponsive.css'; // Importer les styles spécifiques pour le menu burger

function MobileMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Gérer l'ouverture/fermeture du menu

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Alterner entre ouvert et fermé
    };

    return (
        <nav className="mobileNav">
                <button className="burgerMenu" onClick={toggleMenu}>
                &#9776; {/* Icône burger */}
                </button>
            <ul className={`mobileMenuItems ${isMenuOpen ? 'open' : ''}`}>
                <li><Link to="/" onClick={toggleMenu}>Accueil</Link></li>
                <li><Link to="/prestations" onClick={toggleMenu}>Nos Prestations</Link></li>
                <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
        </nav>
    );
}

export default MobileMenu;
