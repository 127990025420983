import React, { useState, useEffect, useRef } from 'react';
import './style/Partners.css';

// Fonction pour charger dynamiquement les images du dossier spécifié
const importAllImages = (r) => {
    return r.keys().map(r);
};

const Partners = () => {
    const [currentIndex, setCurrentIndex] = useState(0); // Index du centre (élément 3)
    const [isAnimating, setIsAnimating] = useState(false); // Contrôle l'état de l'animation
    const [direction, setDirection] = useState('right'); // Direction de l'animation
    const intervalRef = useRef(null); // Référence pour l'intervalle de défilement automatique

    // Importer toutes les images du dossier '../medias/pictures/partnersLogos/'
    const images = importAllImages(require.context('../medias/pictures/partnersLogos/', false, /\.(png|jpe?g|svg)$/));

    // Créer des objets partenaires avec les images importées
    const partners = images.map((image, index) => ({
        id: index + 1,
        logo: image, // Chemin de l'image
    }));

    const getFiveImages = (startIndex) => {
        // Retourne les cinq images visibles en partant de `startIndex`
        const images = [];
        for (let i = -2; i <= 2; i++) {
            images.push(partners[(startIndex + i + partners.length) % partners.length]);
        }
        return images;
    };

    const handlePrevClick = () => {
        if (isAnimating) return; // Empêche de cliquer pendant l'animation
        setIsAnimating(true); // Démarre l'animation
        setDirection('right'); // Définit la direction vers la droite (inversé)

        // Détermine le nouvel index de départ pour le défilement vers la droite
        const newStartIndex = (currentIndex + 1) % partners.length;

        setTimeout(() => {
            setCurrentIndex(newStartIndex); // Met à jour l'index de départ
            setIsAnimating(false); // Termine l'animation
        }, 500); // Durée de l'animation
    };

    const handleNextClick = () => {
        if (isAnimating) return; // Empêche de déclencher pendant l'animation
        setIsAnimating(true); // Démarre l'animation
        setDirection('left'); // Définit la direction vers la gauche (inversé)

        // Détermine le nouvel index de départ pour le défilement vers la gauche
        const newStartIndex = (currentIndex - 1 + partners.length) % partners.length;

        setTimeout(() => {
            setCurrentIndex(newStartIndex); // Met à jour l'index de départ
            setIsAnimating(false); // Termine l'animation
        }, 500); // Durée de l'animation
    };

    const startAutoScroll = () => {
        // Fonction de défilement automatique à gauche toutes les 3 secondes
        intervalRef.current = setInterval(() => {
            if (!isAnimating) { // Vérifie si aucune animation n'est en cours
                setIsAnimating(true); // Démarre l'animation
                setDirection('left'); // Définit la direction vers la gauche pour l'auto-défilement

                const newStartIndex = (currentIndex - 1 + partners.length) % partners.length;

                setTimeout(() => {
                    setCurrentIndex(newStartIndex); // Met à jour l'index de départ
                    setIsAnimating(false); // Termine l'animation
                }, 500); // Durée de l'animation
            }
        }, 3000);
    };

    useEffect(() => {
        startAutoScroll(); // Démarre le défilement automatique au montage du composant

        return () => {
            clearInterval(intervalRef.current); // Nettoie l'intervalle lors du démontage du composant
        };
    }, [currentIndex]); // Ajoute `currentIndex` comme dépendance

    const visibleImages = getFiveImages(currentIndex); // Images visibles

    return (

        <>
        <h2 className="partnersTitle">Ils nous font confiance</h2>

        <div className="partners-carousel">

            <button className="carousel-btn prev-btn" onClick={handlePrevClick}>
                &#10094;
            </button>

            {/* Conteneur principal qui affiche les 3 images visibles */}
            <div className="carousel-wrapper">
                {/* Conteneur caché à gauche */}
                <div className="carousel-hidden-left"></div>
                {/* Conteneur pour les 5 images générées */}
                <div className="carousel-visible-wrapper">
                    {visibleImages.map((partner, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${isAnimating ? (direction === 'right' ? 'animate-left' : 'animate-right') : ''}`}
                            style={{
                                backgroundColor: 'black', // Fond noir pour chaque carré
                            }}
                        >
                            {/* Affichage de l'image */}
                            <img src={partner.logo} alt={`Logo du partenaire : ${partner.id}`} className="partner-logo" />
                        </div>
                    ))}
                </div>
                {/* Conteneur caché à droite */}
                <div className="carousel-hidden-right"></div>
            </div>

            <button className="carousel-btn next-btn" onClick={handleNextClick}>
                &#10095;
            </button>
        </div>

        </>
    );
};

export default Partners;
