import React, { useEffect, useState } from 'react';
import Partners from './components/Partners'; // Composant desktop
import PartnersResponsive from './responsive/components/PartnersResponsive'; // Composant mobile

const ResponsiveSetup = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const checkScreenSize = () => {
        console.log("Current window width:", window.innerWidth); // Affiche la largeur actuelle
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        console.log("isMobile state:", isMobile); // Vérifie l'état isMobile
        console.log(isMobile ? "Rendering PartnersResponsive" : "Rendering Partners");
    }, [isMobile]);

    return (
        <div>
            {isMobile ? <PartnersResponsive /> : <Partners />}
        </div>
    );
};

export default ResponsiveSetup;
