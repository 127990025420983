import React, { useState, useEffect } from 'react'; // Import des hooks
import "./style/Contact.css";
import "../responsive/pages/style/ContactResponsive.css"
import ContactForm from "../components/ContactForm";

const AnimatedText = () => {
    const phrases = ["Un projet ?", "Une idée ?", "Une question ?"];
    const [phraseIndex, setPhraseIndex] = useState(0); // Pour suivre la phrase actuelle
    const [letterIndex, setLetterIndex] = useState(0); // Pour suivre la lettre actuelle
    const [isDeleting, setIsDeleting] = useState(false); // Suivre l'état d'effacement
    const [displayedLetters, setDisplayedLetters] = useState([]); // Lettres actuellement affichées
    const [speed, setSpeed] = useState(200); // Vitesse d'affichage et de suppression

    useEffect(() => {
        const currentPhrase = phrases[phraseIndex].split(''); // Transformer la phrase en tableau de lettres

        const handleTyping = () => {
            if (!isDeleting) {
                // Ajouter des lettres une par une
                if (letterIndex < currentPhrase.length) {
                    setDisplayedLetters((prevLetters) => [
                        ...prevLetters,
                        { letter: currentPhrase[letterIndex] === ' ' ? '\u00A0' : currentPhrase[letterIndex], key: letterIndex, animationClass: "bounce-in" }
                    ]);
                    setLetterIndex(letterIndex + 1);
                    setSpeed(150); // Vitesse d'apparition des lettres
                } else {
                    // Pause une fois que la phrase entière est affichée
                    setTimeout(() => setIsDeleting(true), 1000); // Attend avant d'effacer
                }
            } else {
                // Supprimer des lettres une par une
                if (letterIndex > 0) {
                    setDisplayedLetters((prevLetters) =>
                        prevLetters.map((item, idx) =>
                            idx === letterIndex - 1 ? { ...item, animationClass: "bounce-out" } : item
                        )
                    );
                    setTimeout(() => {
                        setDisplayedLetters((prevLetters) => prevLetters.slice(0, -1)); // Supprimer la lettre après animation
                        setLetterIndex(letterIndex - 1);
                        setSpeed(50); // Vitesse de disparition des lettres
                    }, 50); // Délai avant de supprimer
                } else {
                    // Passer à la phrase suivante une fois que toute la phrase est effacée
                    setIsDeleting(false);
                    setPhraseIndex((prev) => (prev + 1) % phrases.length); // Boucle sur les phrases
                }
            }
        };

        // Déclencher l'animation à chaque intervalle de "speed"
        const timer = setTimeout(() => {
            handleTyping();
        }, speed);

        // Nettoyer le timeout après chaque cycle
        return () => clearTimeout(timer);
    }, [letterIndex, isDeleting, speed, phraseIndex, phrases]);

    return (
        <h2 className="animated-text">
            {displayedLetters.map((item) => (
                <span key={item.key} className={item.animationClass}>
                    {item.letter}
                </span>
            ))}
        </h2>
    );
};

function Contact() {
    return (
        <>
            <div className="contactBackground">
                <div className="contactPageContainer">
                    <div className="contactTextContainer">
                        <AnimatedText />
                        <p className="contactText">Vous pouvez nous contacter via le formulaire ci-dessous, nous vous répondrons dans les plus brefs délais.</p>
                    </div>

                    <ContactForm />
                </div>
            </div>
        </>
    );
}

export default Contact;
