import React, { useState, useEffect, useRef } from 'react';
import './style/PartnersResponsive.css'; // Importe le CSS spécifique pour mobile

const importAllImages = (r) => {
    return r.keys().map(r);
};

const PartnersResponsive = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [direction, setDirection] = useState('right');
    const intervalRef = useRef(null);

    const images = importAllImages(require.context('../../medias/pictures/partnersLogos/', false, /\.(png|jpe?g|svg)$/));

    const partners = images.map((image, index) => ({
        id: index + 1,
        logo: image,
    }));

    const getThreeImages = (startIndex) => {
        const images = [];
        for (let i = -1; i <= 1; i++) {
            images.push(partners[(startIndex + i + partners.length) % partners.length]);
        }
        return images;
    };

    const handlePrevClick = () => {
        if (isAnimating) return;
        setIsAnimating(true);
        setDirection('right');

        const newStartIndex = (currentIndex + 1) % partners.length;

        setTimeout(() => {
            setCurrentIndex(newStartIndex);
            setIsAnimating(false);
        }, 500);
    };

    const handleNextClick = () => {
        if (isAnimating) return;
        setIsAnimating(true);
        setDirection('left');

        const newStartIndex = (currentIndex - 1 + partners.length) % partners.length;

        setTimeout(() => {
            setCurrentIndex(newStartIndex);
            setIsAnimating(false);
        }, 500);
    };

    const startAutoScroll = () => {
        intervalRef.current = setInterval(() => {
            if (!isAnimating) {
                setIsAnimating(true);
                setDirection('left');

                const newStartIndex = (currentIndex - 1 + partners.length) % partners.length;

                setTimeout(() => {
                    setCurrentIndex(newStartIndex);
                    setIsAnimating(false);
                }, 500);
            }
        }, 3000);
    };

    useEffect(() => {
        startAutoScroll();

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [currentIndex]);

    const visibleImages = getThreeImages(currentIndex);

    return (
        <div>
        <p className="partnersTitle">Ils nous ont fait confiance</p>

        <div className="partners-carousel-responsive">
            <button className="carousel-btn-responsive prev-btn-responsive" onClick={handlePrevClick}>
                &#10094;
            </button>

            <div className="carousel-wrapper-responsive">
                <div className="carousel-hidden-left-responsive"></div> {/* Cache à gauche */}
                <div className="carousel-visible-wrapper-responsive">
                    {visibleImages.map((partner, index) => (
                        <div
                            key={index}
                            className={`carousel-item-responsive ${isAnimating ? (direction === 'right' ? 'animate-left-responsive' : 'animate-right-responsive') : ''}`}
                        >
                            <img src={partner.logo} alt={`Partner ${partner.id}`} className="partner-logo-responsive" />
                        </div>
                    ))}
                </div>
                <div className="carousel-hidden-right-responsive"></div> {/* Cache à droite */}
            </div>

            <button className="carousel-btn-responsive next-btn-responsive" onClick={handleNextClick}>
                &#10095;
            </button>
        </div>
        </div>
    );
};

export default PartnersResponsive;
