import React, { useState } from 'react';
import './style/ContactForm.css';
import '../responsive/components/style/ContactFormResponsive.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        lastName: '',
        firstName: '',
        company: '',
        phone: '',
        email: '',
        eventType: '',
        details: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Fonction pour gérer les changements de chaque champ du formulaire
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Fonction de validation des champs du formulaire
    const validateForm = () => {
        const newErrors = {};
        if (!formData.lastName.trim()) newErrors.lastName = 'Le nom est requis.';
        if (!formData.firstName.trim()) newErrors.firstName = 'Le prénom est requis.';
        if (!formData.phone.trim()) newErrors.phone = 'Le numéro de téléphone est requis.';
        if (!formData.email.trim()) {
            newErrors.email = 'L\'adresse e-mail est requise.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'L\'adresse e-mail est invalide.';
        }
        if (!formData.eventType.trim()) newErrors.eventType = 'Veuillez sélectionner un type d\'événement.';
        if (!formData.details.trim()) newErrors.details = 'Veuillez entrer les détails de votre demande.';

        return newErrors;
    };

    // Fonction pour formater les données du formulaire avant l'envoi
    const formatFormData = (data) => {
        return `
    Nouvelle demande de contact :

    Nom : ${data.lastName}
    Prénom : ${data.firstName}
    Entreprise : ${data.company || 'Non spécifié'}
    Téléphone : ${data.phone}
    Email : ${data.email}
    Type d'événement : ${data.eventType}
    Détails de la demande :
    ${data.details}
  `;
    };

    // Fonction pour envoyer l'e-mail avec EmailJS
    const sendEmail = (formattedMessage) => {
        emailjs.send(
            'service_b44w4el',
            'template_2ozn6jh',
            {
                message: formattedMessage, // Utiliser le message formaté
                from_name: `El Pintxo Events - Réservation`,
                reply_to: formData.email,
            },
            '1IErJRntMOjhMTRb2' // EmailJS UserID
        ).then((result) => {
            console.log('E-mail envoyé avec succès !', result.text);
            setIsSubmitted(true);
        }).catch((error) => {
            console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
        });
    };

    // Fonction de gestion de la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formattedMessage = formatFormData(formData);
        sendEmail(formattedMessage); // Envoyer l'e-mail

        setErrors({});
        setFormData({
            lastName: '',
            firstName: '',
            company: '',
            phone: '',
            email: '',
            eventType: '',
            details: '',
        });
    };

    return (
            <div className="contact-form-container">
                <h2>Contactez-nous</h2>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="form-row">
                        <div className="form-group half-width">
                            <label htmlFor="lastName">Nom<span className="required">*</span> :</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                className={errors.lastName ? 'error' : ''}
                                placeholder="Votre nom"
                            />
                            {errors.lastName && <p className="error-message">{errors.lastName}</p>}
                        </div>

                        <div className="form-group half-width">
                            <label htmlFor="firstName">Prénom<span className="required">*</span> :</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className={errors.firstName ? 'error' : ''}
                                placeholder="Votre prénom"
                            />
                            {errors.firstName && <p className="error-message">{errors.firstName}</p>}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group half-width">
                            <label htmlFor="company">Entreprise :</label>
                            <input
                                type="text"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                placeholder="Nom de votre entreprise"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group half-width">
                            <label htmlFor="email">Email<span className="required">*</span> :</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={errors.email ? 'error' : ''}
                                placeholder="Votre adresse e-mail"
                            />
                            {errors.email && <p className="error-message">{errors.email}</p>}
                        </div>

                        <div className="form-group half-width">
                            <label htmlFor="phone">Téléphone<span className="required">*</span> :</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={errors.phone ? 'error' : ''}
                                placeholder="Votre numéro de téléphone"
                            />
                            {errors.phone && <p className="error-message">{errors.phone}</p>}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group half-width">
                            <label htmlFor="eventType">Type d'événement<span className="required">*</span> :</label>
                            <select
                                id="eventType"
                                name="eventType"
                                value={formData.eventType}
                                onChange={handleChange}
                                className={errors.eventType ? 'error' : ''}
                            >
                                <option value="">Sélectionnez un type d'événement</option>
                                <option value="Mariage">Mariage</option>
                                <option value="Enterrement de vie de jeune fille / vie de garçon">Enterrement de vie de jeune fille / vie de garçon</option>
                                <option value="Séminaire">Séminaire</option>
                                <option value="Evénement d'entreprise / Inauguration">Événement d'entreprise / Inauguration</option>
                                <option value="Festival / Evénement accueillant du public">Festival / Événement accueillant du public</option>
                                <option value="Autre">Autre</option>
                            </select>
                            {errors.eventType && <p className="error-message">{errors.eventType}</p>}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group full-width ">
                            <label htmlFor="details">Détails de la demande<span className="required">*</span> :</label>
                            <textarea
                                id="details"
                                name="details"
                                value={formData.details}
                                onChange={handleChange}
                                className={errors.details ? 'error' : ''}
                                placeholder="Décrivez votre demande"
                            />
                            {errors.details && <p className="error-message">{errors.details}</p>}
                        </div>
                    </div>

                    {isSubmitted && <p className="success-message">Merci pour votre demande ! Nous reviendrons vers vous sous peu.</p>}

                    <button type="submit" className="submit-btn">Envoyer la demande</button>
                    <p className="note-required">Les champs marqués d'une <span className="required">*</span> sont obligatoires.</p>
                </form>
            </div>
    );
};

export default ContactForm;
