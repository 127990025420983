import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Composants présents sur toutes les pages
import Header from './components/Header';
import Footer from './components/Footer';

// Pages
import Prestations from './pages/Prestations';
import Contact from './pages/Contact';
import Home from './pages/Home';

// Responsive Setup pour le bandeau partenaires
import ResponsiveSetup from './ResponsiveSetup'; // Basculement mobile/desktop pour Partners

function App() {
    return (
        <Router>
            <Header/>

            {/* Définir les routes */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/prestations" element={<Prestations />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>

            <ResponsiveSetup /> {/* Le bandeau des partenaires avant le footer */}

            <Footer/>
        </Router>
    );
}

export default App;
